/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, SeparateLine, Voucher, Image, Button, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="--center" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex css-1qa3oz css-pcgz3u --shape2 --shadow2 --justify el--1 --full --parallax pb--80 pr--40 pt--80 flex--bottom" animS={"3"} columns={"1"} parallax={true} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/21246/c0986e0a00064d1087976b44959f57e3_s=3000x_.png);
    }
  
background-position: 79% 87%;
    `}>
            
            <ColumnWrapper className="js-anim  --anim6" anim={"6"} animS={null} style={{"maxWidth":1275}}>
              
              <Text className="text-box fs--72" content={"<span style=\"font-weight: bold;\">Dárkový poukaz </span>"}>
              </Text>

              <Text className="text-box fs--20" content={"<span style=\"color: var(--black);\">na</span> <a href=\"https://www.instagram.com/_harmony_jewellery_/?hl=en\" target=\"_blank\" style=\"color: rgb(49, 49, 49);\">originální ručně vyráběné šperky Harmony Jewellery</a><span style=\"color: var(--black);\">&nbsp;</span><br><span style=\"color: var(--black);\">z minerálních kamenů vyrobené s láskou a srdcem pro Vaše blízké.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pl--40 pr--40" name={"n3igajoyabc"} layout={"l6"}>
        </SeparateLine>


        <Column className="--center pb--02 pt--02" name={"0jqk5s7yshjr"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Voucher className="pb--40 pt--40" name={"ho1tcto4v89"}>
        </Voucher>


        <Column className="--center pb--02 pt--02" name={"88lk87yxjxf"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--20 pt--20" name={"xzol7ugqf6j"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l3"} parallax={true} css={css`
      background-position: 15% 52%;
    `}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" animS={"5"} style={{"maxWidth":1398}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim6" anim={"6"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/21246/cc6774e6bf524ab3bdc2ef67c042281c_s=860x_.png"} sizes={"100vw"} style={{"maxWidth":349}} srcSet={"https://cdn.swbpg.com/t/21246/cc6774e6bf524ab3bdc2ef67c042281c_s=350x_.png 350w, https://cdn.swbpg.com/t/21246/cc6774e6bf524ab3bdc2ef67c042281c_s=660x_.png 660w, https://cdn.swbpg.com/t/21246/cc6774e6bf524ab3bdc2ef67c042281c_s=860x_.png 860w, https://cdn.swbpg.com/t/21246/cc6774e6bf524ab3bdc2ef67c042281c_s=1400x_.png 1400w, https://cdn.swbpg.com/t/21246/cc6774e6bf524ab3bdc2ef67c042281c_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box" content={"Poukaz k tisku &nbsp;500 Kč"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 fs--13 mt--08" url={"https://cdn.swbpg.com/d/21246/f1e86de3958e4f119077e571273553ce.png"} href={"https://cdn.swbpg.com/d/21246/f1e86de3958e4f119077e571273553ce.png"} content={"TISKNOUT"} document={"f1e86de3958e4f119077e571273553ce"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim6" anim={"6"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/21246/49f8f2c9ade3426586fbcaef3d7debae_s=860x_.png"} sizes={"100vw"} style={{"maxWidth":349}} srcSet={"https://cdn.swbpg.com/t/21246/49f8f2c9ade3426586fbcaef3d7debae_s=350x_.png 350w, https://cdn.swbpg.com/t/21246/49f8f2c9ade3426586fbcaef3d7debae_s=660x_.png 660w, https://cdn.swbpg.com/t/21246/49f8f2c9ade3426586fbcaef3d7debae_s=860x_.png 860w, https://cdn.swbpg.com/t/21246/49f8f2c9ade3426586fbcaef3d7debae_s=1400x_.png 1400w, https://cdn.swbpg.com/t/21246/49f8f2c9ade3426586fbcaef3d7debae_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box" content={"Poukaz k tisku 1 000 Kč"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 btn-box--cColor1 fs--13 mt--08" url={"https://cdn.swbpg.com/d/21246/53480aa3646c4bcba28f344e3facc303.png"} href={"https://cdn.swbpg.com/d/21246/59d2442b5c964f00bb7e5f3857d6d307.png"} content={"TISKNOUT"} document={"59d2442b5c964f00bb7e5f3857d6d307"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim6" anim={"6"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/21246/8768c01e9e5848a99f4e0aed24c707af_s=860x_.png"} sizes={"100vw"} style={{"maxWidth":349}} srcSet={"https://cdn.swbpg.com/t/21246/8768c01e9e5848a99f4e0aed24c707af_s=350x_.png 350w, https://cdn.swbpg.com/t/21246/8768c01e9e5848a99f4e0aed24c707af_s=660x_.png 660w, https://cdn.swbpg.com/t/21246/8768c01e9e5848a99f4e0aed24c707af_s=860x_.png 860w, https://cdn.swbpg.com/t/21246/8768c01e9e5848a99f4e0aed24c707af_s=1400x_.png 1400w, https://cdn.swbpg.com/t/21246/8768c01e9e5848a99f4e0aed24c707af_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box" content={"Poukaz k tisku 1 500 Kč"}>
              </Text>

              <Button className="btn-box btn-box--hvr5 fs--13 mt--08" url={"https://cdn.swbpg.com/d/21246/53480aa3646c4bcba28f344e3facc303.png"} href={"https://cdn.swbpg.com/d/21246/f1e86de3958e4f119077e571273553ce.png"} content={"TISKNOUT<br>"} document={"f1e86de3958e4f119077e571273553ce"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--02 pt--02" name={"88lk87yxjxf"} style={{"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"yq596cjrcn"} layout={"l6"}>
        </SeparateLine>


        <Column className="pb--08 pt--08" animS={"4"} style={{"backgroundColor":"var(--color-dominant)"}}>
          
          <ColumnWrap className="column__flex --center el--3 pb--25 flex--center" anim={""} animS={"3"} style={{"maxWidth":1034}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":330}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Jak to funguje</span>"}>
              </Title>

              <Text className="text-box" content={"Objednejte si u nás <span style=\"font-weight: bold;\">Dárkový poukaz</span> a vyberte si šperk dle vlastního výběru."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":300}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" content={"Ing. Soňa Hřebačková<br>U Rotundy 961, 691 42 Valtice<br><span style=\"font-weight: bold; text-decoration: underline;\">Česká Republika</span><br>00421 733 133 676<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":330}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">O nás</span>"}>
              </Title>

              <Text className="text-box" content={"Krásu a magii drahých kamenů a minerálů přetvářím do ručně vyráběných šperků.&nbsp;Moje šperky&nbsp;<span style=\"font-weight: bold;\">vyrábím s vášní pro eleganci, detail a s láskou&nbsp;</span>pro Vás.&nbsp; &nbsp;&nbsp;<span style=\"font-weight: bold;\">Každý šperk je originál. </span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80" style={{"backgroundColor":"rgba(32, 32, 32, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}